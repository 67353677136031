<template>
  <div>
    <el-form
        :size="size"
        class="form"
    >
      <div class="d-flex justify-content-between">
        <el-form-item
            :label="$t('system.name')"
            class="form__item flex-grow-1"
            required
        >
          <el-input
              :class="{'validate-error': !!getBackendErrors('name')}"
              v-model="form.name"
          />
          <div
              class="el-form-item__error"
              v-if="getBackendErrors('name')"
          >
            {{ $t(getBackendErrors('name')[0]) }}
          </div>
        </el-form-item>
        <el-form-item
            :label="$t('system.description')"
            class="form__item"
        >
          <el-input v-model="form.description" />
        </el-form-item>
        <el-form-item
            :label="$t('system.category')"
            class="form__item flex-grow-1"
            required
        >
          <el-select
              :class="{'validate-error': !!getBackendErrors('category_id')}"
              class="w-100"
              filterable
              v-model="form.category_id"
          >
            <el-option
                :key="category.id"
                :label="category.name"
                :value="category.id"
                v-for="category in categories"
            />
          </el-select>
          <div
              class="el-form-item__error"
              v-if="getBackendErrors('category_id')"
          >
            {{ $t(getBackendErrors('category_id')[0]) }}
          </div>
        </el-form-item>
        <el-form-item
            :label="$t('system.status')"
            class="form__item flex-grow-1"
        >
          <el-switch
              active-color="#13ce66"
              class="w-100"
              inactive-color="#ff4949"
              v-model="form.status"
          />
        </el-form-item>
      </div>
      <div class="d-flex justify-content-between">
        <el-form-item
            :label="$t('system.rate_description')"
            class="form__item"
        >
          <el-input v-model="form.rate_description" />
        </el-form-item>
        <el-form-item
            :label="$t('system.rate')"
            class="form__item flex-grow-1"

        >
          <el-input-number
              :min="0"
              @change="updateTotalValue"
              class="w-100"
              controls-position="right"
              v-model.number="form.rate"
          />
        </el-form-item>
        <el-form-item
            :label="$t('system.hours')"
            class="form__item flex-grow-1"
        >
          <el-input-number
              :min="0"
              @change="updateTotalValue"
              class="w-100"
              controls-position="right"
              v-model.number="form.hours"
          />
        </el-form-item>
        <el-form-item
            :label="$t('system.total')"
            class="form__item flex-grow-1"
        >
          <el-input-number
              :controls="false"
              :min="0"
              class="w-100"
              disabled
              v-model.number="form.total"
          />
        </el-form-item>
      </div>
      <div class="d-flex justify-content-end">
        <el-button
            :loading="saveLoading"
            :size="size"
            @click="saveService"
            type="primary"
        >
          {{ $t('system.save') }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios';

const emptyForm = {
  name: '',
  description: '',
  category_id: null,
  status: true,
  rate: 0,
  rate_description: '',
  hours: 0,
  total: 0,
};

export default {
  name: 'WOPaymentsServiceForm',
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    backendErrors: {},
    saveLoading: false,
    size: 'mini',
    form: {
      name: '',
      description: '',
      category_id: null,
      status: true,
      rate: 0,
      rate_description: '',
      hours: 0,
      total: 0,
    },
  }),
  methods: {
    getBackendErrors(field) {
      return this.backendErrors[field];
    },
    updateTotalValue() {
      this.form.total = Math.floor(this.form.hours * this.form.rate);
    },
    close() {
      this.$emit('close');
    },
    async saveService() {
      try {
        this.saveLoading = true;
        const {data} = await axios.post('workorders/service_definition', this.form);
        this.$emit('serviceCreated', data[0]);
        this.form = {...emptyForm};
        this.backendErrors = {};
        this.close();
      } catch (error) {
        console.log('error.response.data', error.response.data);
        if (error?.response?.data) {
          this.backendErrors = error.response.data;
        }
      } finally {
        this.saveLoading = false;
      }
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.form {
  &__item {
    padding: 0 10px;
    flex: 2 1 0;
  }
}
</style>
