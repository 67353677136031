<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <el-popover
            :value="isServiceSelectVisible"
            placement="bottom"
            trigger="manual"
            width="300"
        >
          <DebounceInput
              :placeholder="$t('system.select_service')"
              :size="size"
              @blur="toggleServiceSelect('blur')"
              @change="fetchServicesList"
              @focus="toggleServiceSelect('focus')"
              slot="reference"
              v-model="searchServiceString"
              :suffix-icon="`el-icon-arrow-${isServiceSelectVisible ? 'up' : 'down'}`"
          />
          <div class="select-service">
            <template v-if="servicesGroups.length">
              <div
                  :key="group.id"
                  class="service-group"
                  v-for="group in servicesGroups"
              >
                <div class="mx-2 service-group__title">{{ group.name }}</div>
                <div
                    :key="service.id"
                    @click="setServiceToTable(service)"
                    class="service"
                    v-for="service in group.services"
                >
                  <div class="service__title">{{ service.name }}</div>
                  <div class="service__additional-info">{{ service.description }}</div>
                </div>
              </div>
            </template>
            <div
                class="text-center"
                v-else
            >
              <span class="no-date-title">
                {{ $t('system.no_data') }}
              </span>
            </div>
          </div>
        </el-popover>
        <el-popover
            placement="top-start"
            ref="serviceForm"
            trigger="click"
            width="800"
        >
          <WOPaymentsServiceForm
              :categories="serviceCategories"
              @serviceCreated="onServiceCreated"
          />
          <el-button
              :size="size"
              class="ml-2"
              slot="reference"
              type="primary"
          >
            <font-awesome-icon icon="plus" />
          </el-button>
        </el-popover>
      </div>
      <el-button
          :loading="loading"
          :size="size"
          @click="saveTableData"
          type="success"
      >
        {{ $t('system.save') }}
      </el-button>
    </div>
    <DataTable
        :headers="tableHeaders"
        :loading="loading"
        :model="services"
        :pagination="false"
        class="table"
    >
      <!--      <template v-slot:item.isChecked="{row}">-->
      <!--        <div class="mx-1 d-flex justify-content-center align-content-center">-->
      <!--          <el-checkbox v-model="row.isChecked" class="m-0"/>-->
      <!--        </div>-->
      <!--      </template>-->
      <template v-slot:item.service_name="{row, idx}">
        <div class="mx-1">
          <el-input
              :class="{'validate-error': !isFieldValid(idx, 'name')}"
              :size="size"
              v-model="row.name"
          />
        </div>
      </template>
      <template v-slot:item.description="{row, idx}">
        <div class="mx-1">
          <el-input
              :class="{'validate-error': !isFieldValid(idx, 'description')}"
              :size="size"
              v-model="row.description"
          />
        </div>
      </template>
      <template v-slot:item.hours="{row, idx}">
        <div
            @click.stop
            class="d-flex justify-content-center mx-1"
        >
          <el-input-number
              :class="{'validate-error': !isFieldValid(idx, 'hours')}"
              :min="0"
              :size="size"
              @change="updateTotalRowValue(row)"
              controls-position="right"
              v-model.number="row.hours"
          />
        </div>
      </template>

      <template v-slot:item.multiplication>
        <div class="d-flex justify-content-center mx-2">
          x
        </div>
      </template>

      <template v-slot:item.rate="{row, idx}">
        <div
            @click.stop
            class="d-flex justify-content-center mx-2"
        >
          <el-input-number
              :class="{'validate-error': !isFieldValid(idx, 'rate')}"
              :min="0"
              :size="size"
              @change="updateTotalRowValue(row)"
              controls-position="right"
              v-model.number="row.rate"
          />
        </div>
      </template>
      <template v-slot:item.equals>
        <div class="d-flex justify-content-center mx-2">
          =
        </div>
      </template>
      <template v-slot:item.total="{row}">
        <div class="d-flex justify-content-center mx-2">
          <b>${{ row.total.toFixed(2) }}</b>
        </div>
      </template>
      <template v-slot:item.options="{row}">
        <div class="d-flex justify-content-center align-content-center cell_remove">
          <i
              @click.stop="removeItem(row)"
              slot="reference"
          >
            <font-awesome-icon icon="times" />
          </i>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import DataTable from '@/components/Table/DataTable';
import WOPaymentsServiceForm from '@/components/WorkOrderComponents/Payments/ProductsAndServices/ServiceForm';
import notification from '@/notification/notify';
import validationMixin from '@/components/WorkOrderComponents/Payments/validationMixin';
import DebounceInput from '@/components/DebounceInput';
import swal from 'sweetalert2';

export default {
  name: 'ProductsAndServices',
  mixins: [validationMixin],
  components: {
    DebounceInput,
    WOPaymentsServiceForm,
    DataTable,
  },
  props: {
    workOrderId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    size: 'mini',
    tableHeaders: [
      {
        key: 'options',
        label: '',
        sort: false,
        width: '36px',
      },
      {
        key: 'service_name',
        label: 'system.service_name',
        sort: false,
      },
      {
        key: 'description',
        label: 'system.description',
        sort: false,
      },
      {
        key: 'hours',
        label: 'system.hours',
        width: '200px',
        sort: false,
      },
      {
        key: 'multiplication',
        label: '',
        sort: false,
        width: '36px',
      },
      {
        key: 'rate',
        label: 'system.rate',
        width: '200px',
        sort: false,
      },
      {
        key: 'equals',
        label: '',
        sort: false,
        width: '36px',
      },
      {
        key: 'total',
        label: 'system.total',
        width: '200px',
        sort: false,
      },
    ],
    isServiceSelectVisible: false,
    searchServiceString: '',
    selectedServiceId: null,
    serviceCategories: [],
    servicesGroups: [],
    loading: false,
  }),
  computed: {
    ...mapGetters('workOrderPayments', ['workOrderServices']),
    services() {
      return this.workOrderServices(this.workOrderId);
    },
    filteredServicesGroups() {
      if (!this.searchServiceString.length) {
        return this.servicesGroups;
      }
      return this.servicesGroups.filter(group => {
        const filteredServices = group.services.filter(service =>
            service.name.toLowerCase().includes(this.searchServiceString.toLowerCase()),
        );
        return filteredServices.length;
      });
    },
  },
  async created() {
    await this.fetchCategories();
    await this.fetchServicesList();
  },
  methods: {
    ...mapActions('workOrderPayments', ['addServiceItem', 'removeServiceItem']),
    async fetchCategories() {
      try {
        const {data} = await axios.get('workorders/expense/service_category');
        this.serviceCategories = data;
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },
    onServiceCreated(newService) {
      this.setServiceToTable(newService);
      this.$refs.serviceForm.showPopper = false;
      this.fetchServicesList();
    },
    async fetchServicesList() {
      try {
        const {data} = await axios.get(`workorders/service_definition`, {
          params: {
            search: this.searchServiceString,
          },
        });
        this.servicesGroups = [];
        data.forEach(service => {
          const servicesGroupCandidate = this.servicesGroups.find(group => group.id === service.category_id);
          if (servicesGroupCandidate) {
            servicesGroupCandidate.services.push(service);
          } else {
            let categoryName = this.$t('system.no_category');
            const categoryCandidate = this.serviceCategories.find(category =>
                category.id === service.category_id);
            if (categoryCandidate) {
              categoryName = categoryCandidate.name;
            }
            this.servicesGroups.push({
              id: service.category_id,
              name: categoryName,
              services: [],
            });
          }
        });
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_fetch_service_categories'),
            'error');
      }
    },
    toggleServiceSelect() {
      this.isServiceSelectVisible = !this.isServiceSelectVisible;
    },
    updateTotalRowValue(row) {
      row.rate = (Math.round((row.rate) * 100) / 100) || 0;
      row.hours = row.hours || 0;
      row.total = Math.round(row.hours * row.rate * 100) / 100;
    },
    setServiceToTable(service) {
      this.addServiceItem({service, workOrderId: this.workOrderId});
    },
    async removeItem(row) {
      const result = await swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        this.removeServiceItem({serviceId: row.id, workOrderId: this.workOrderId});
      }
    },
    async saveTableData() {
      try {
        this.loading = true;
        await axios.post(`workorders/expense/${this.workOrderId}/product_service`, {positions: this.services});
        this.validationErrors = {};
        notification.notify(
            this.$t('notify.success'),
            this.$t('system.saved'),
            'success');
      } catch (error) {
        if (error.response?.status === 422) {
          this.setValidationData(error.response.data);
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.check_fields'),
              'warning');
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.select-service {
  max-height: 200px;
  overflow-y: auto;

  .service-group {
    border-bottom: 1px solid #d1d9db;
    padding: 5px 0;

    &:last-child {
      border-bottom: none;
    }

    &__title {
      font-weight: bold;
    }

    .service {
      padding: 0 5px;

      &:hover {
        background: #edf0f1;
      }

      &__title {
        line-height: normal;
        padding: 8px 0 4px;
        color: #2e6da4;
      }

      &__additional-info {
        word-break: break-word;
        font-size: .9em;
        line-height: normal;
        padding: 4px 0 8px;
      }
    }
  }
}

.table {
  .cell_remove {
    i {
      padding: 3px 7px;

      &:hover {
        color: red;
      }
    }
  }
}
</style>
